var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("Header", {
        attrs: {
          title: _vm.txt.pageTitle,
          subtitle: _vm.txt.pageSubtitle,
          "items-bread-crumb": _vm.itemsBreadCrumb,
          "has-add-button": false,
          "has-update-info": false,
        },
        on: { clickAdd: _vm.onClickAdd },
      }),
      _c(
        "v-card",
        { staticClass: "px-0 pt-0 pb-0" },
        [
          _c(
            "v-card-title",
            [
              _c(
                "div",
                { staticClass: "divSearch" },
                [
                  _c("TableSearch", {
                    attrs: {
                      id: "search",
                      "style-custom": _vm.styleForSearch,
                      label: _vm.txt.tableSearchLabel,
                      disabled: _vm.loadingTable,
                    },
                    on: { input: _vm.onInputSearch },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c("v-spacer"),
              _c("FilterEncapsulated", {
                ref: "filter",
                attrs: {
                  "show-title": false,
                  disabled: _vm.loadingTable,
                  "disabled-filter-apply-btn": _vm.disableApplyFilterButton,
                  "show-switch": false,
                  "height-dialog": _vm.heightForDialog,
                },
                on: {
                  applyFilter: _vm.applyFilter,
                  clearFilters: _vm.clearFiltersLocal,
                  closeFilterDialog: _vm.closeFilterDialog,
                },
                scopedSlots: _vm._u([
                  {
                    key: "filters",
                    fn: function () {
                      return [
                        _c("SelectMultiple", {
                          attrs: {
                            id: "selectFamily",
                            "has-select-all": true,
                            label: _vm.txt.family,
                            disabled: _vm.isFiltersDisabled,
                            items: _vm.filterSelects.groupFamily,
                          },
                          model: {
                            value: _vm.filters.family,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "family", $$v)
                            },
                            expression: "filters.family",
                          },
                        }),
                        _c("SelectMultiple", {
                          attrs: {
                            id: "selectOccupation",
                            "has-select-all": true,
                            label: _vm.txt.occupation,
                            items: _vm.filterSelects.groupCbo,
                            disabled: _vm.isFiltersDisabled,
                          },
                          model: {
                            value: _vm.filters.occupation,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "occupation", $$v)
                            },
                            expression: "filters.occupation",
                          },
                        }),
                        _c("SelectMultiple", {
                          attrs: {
                            id: "selectEducation",
                            "has-select-all": true,
                            label: _vm.txt.education,
                            items: _vm.filterSelects.groupEducation,
                            disabled: _vm.isFiltersDisabled,
                          },
                          model: {
                            value: _vm.filters.education,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "education", $$v)
                            },
                            expression: "filters.education",
                          },
                        }),
                        _c("p", { staticClass: "titleForFilterSwitch" }, [
                          _vm._v(_vm._s(_vm.txt.cota)),
                        ]),
                        _c("AGSwitch", {
                          key: _vm.switches[0].id,
                          staticClass: "addPaddingToSwitch",
                          attrs: {
                            id: _vm.switches[0].id,
                            disabled: _vm.isFiltersDisabled,
                            name: _vm.switches[0].id + "_name",
                            count: _vm.isApprenticeshipQuotaCount,
                            label: _vm.switches[0].label,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.toggleSwitch(_vm.switches[0])
                            },
                          },
                          model: {
                            value: _vm.filters.isApprenticeshipQuota,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filters,
                                "isApprenticeshipQuota",
                                $$v
                              )
                            },
                            expression: "filters.isApprenticeshipQuota",
                          },
                        }),
                        _c("AGSwitch", {
                          key: _vm.switches[1].id,
                          staticClass: "addPaddingToSwitch",
                          attrs: {
                            id: _vm.switches[1].id,
                            disabled: _vm.isFiltersDisabled,
                            count: _vm.isNotApprenticeshipQuotaCount,
                            name: _vm.switches[1].id + "_name",
                            label: _vm.switches[1].label,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.toggleSwitch(_vm.switches[1])
                            },
                          },
                          model: {
                            value: _vm.filters.isNotApprenticeshipQuota,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filters,
                                "isNotApprenticeshipQuota",
                                $$v
                              )
                            },
                            expression: "filters.isNotApprenticeshipQuota",
                          },
                        }),
                        _c("SelectMultiple", {
                          attrs: {
                            id: "selectProfessionalRegulatoryBody",
                            "has-select-all": true,
                            label: _vm.txt.professionalRegulatoryBody,
                            items:
                              _vm.filterSelects.groupProfessionalRegulatoryBody,
                            disabled: _vm.isFiltersDisabled,
                          },
                          model: {
                            value: _vm.filters.professionalRegulatoryBody,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filters,
                                "professionalRegulatoryBody",
                                $$v
                              )
                            },
                            expression: "filters.professionalRegulatoryBody",
                          },
                        }),
                        _c("AGDatePicker", {
                          attrs: {
                            id: "datepickerStartValidity",
                            label: _vm.txt.startValidity,
                            disabled: _vm.isFiltersDisabled,
                          },
                          model: {
                            value: _vm.filters.startValidity,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "startValidity", $$v)
                            },
                            expression: "filters.startValidity",
                          },
                        }),
                        _c("AGDatePicker", {
                          attrs: {
                            id: "datepickerEndValidity",
                            label: _vm.txt.endValidity,
                            disabled: _vm.isFiltersDisabled,
                          },
                          model: {
                            value: _vm.filters.endValidity,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "endValidity", $$v)
                            },
                            expression: "filters.endValidity",
                          },
                        }),
                        _c("AGDatePickerRange", {
                          attrs: {
                            id: "datepickerUpdated",
                            label: _vm.txt.updated,
                            disabled: _vm.isFiltersDisabled,
                          },
                          model: {
                            value: _vm.filters.updated,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "updated", $$v)
                            },
                            expression: "filters.updated",
                          },
                        }),
                        _vm.filterSelects.groupAmendedBy
                          ? _c("SelectMultiple", {
                              attrs: {
                                id: "selectAmendedBy",
                                "has-select-all": true,
                                label: _vm.txt.amendedBy,
                                items: _vm.filterSelects.groupAmendedBy,
                                disabled: _vm.isFiltersDisabled,
                              },
                              model: {
                                value: _vm.filters.amendedBy,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "amendedBy", $$v)
                                },
                                expression: "filters.amendedBy",
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("ExportButton", {
                staticClass: "ml-4",
                attrs: { disabled: _vm.loadingTable },
                on: { click: _vm.onExport },
              }),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "px-0 pb-0" },
            [
              _c("TableCboClassification", {
                attrs: {
                  "table-title": _vm.txt.tableTitle,
                  "loading-table": _vm.loadingTable,
                  headers: _vm.headers,
                  items: _vm.cboClassificationList,
                  "pagination-filter": _vm.cboClassificationPagination,
                  "total-items": _vm.totalCboClassification,
                },
                on: { setPagination: _vm.setPagination },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }