<template>
  <v-main>
    <Header
      :title="txt.pageTitle"
      :subtitle="txt.pageSubtitle"
      :items-bread-crumb="itemsBreadCrumb"
      :has-add-button="false"
      :has-update-info="false"
      @clickAdd="onClickAdd"
    ></Header>
    <v-card class="px-0 pt-0 pb-0">
      <v-card-title>
        <div class="divSearch">
          <TableSearch
            id="search"
            v-model="search"
            :style-custom="styleForSearch"
            :label="txt.tableSearchLabel"
            :disabled="loadingTable"
            @input="onInputSearch"
          ></TableSearch>
        </div>
        <v-spacer></v-spacer>

        <FilterEncapsulated
          ref="filter"
          :show-title="false"
          :disabled="loadingTable"
          :disabled-filter-apply-btn="disableApplyFilterButton"
          :show-switch="false"
          :height-dialog="heightForDialog"
          @applyFilter="applyFilter"
          @clearFilters="clearFiltersLocal"
          @closeFilterDialog="closeFilterDialog"
        >
          <template v-slot:filters>
            <SelectMultiple
              id="selectFamily"
              v-model="filters.family"
              :has-select-all="true"
              :label="txt.family"
              :disabled="isFiltersDisabled"
              :items="filterSelects.groupFamily"
            />

            <SelectMultiple
              id="selectOccupation"
              v-model="filters.occupation"
              :has-select-all="true"
              :label="txt.occupation"
              :items="filterSelects.groupCbo"
              :disabled="isFiltersDisabled"
            />
            <SelectMultiple
              id="selectEducation"
              v-model="filters.education"
              :has-select-all="true"
              :label="txt.education"
              :items="filterSelects.groupEducation"
              :disabled="isFiltersDisabled"
            />
            <p class="titleForFilterSwitch">{{ txt.cota }}</p>
            <AGSwitch
              :id="switches[0].id"
              :key="switches[0].id"
              v-model="filters.isApprenticeshipQuota"
              class="addPaddingToSwitch"
              :disabled="isFiltersDisabled"
              :name="switches[0].id + '_name'"
              :count="isApprenticeshipQuotaCount"
              :label="switches[0].label"
              @input="toggleSwitch(switches[0])"
            />
            <AGSwitch
              :id="switches[1].id"
              :key="switches[1].id"
              v-model="filters.isNotApprenticeshipQuota"
              class="addPaddingToSwitch"
              :disabled="isFiltersDisabled"
              :count="isNotApprenticeshipQuotaCount"
              :name="switches[1].id + '_name'"
              :label="switches[1].label"
              @input="toggleSwitch(switches[1])"
            />
            <SelectMultiple
              id="selectProfessionalRegulatoryBody"
              v-model="filters.professionalRegulatoryBody"
              :has-select-all="true"
              :label="txt.professionalRegulatoryBody"
              :items="filterSelects.groupProfessionalRegulatoryBody"
              :disabled="isFiltersDisabled"
            />

            <AGDatePicker
              id="datepickerStartValidity"
              v-model="filters.startValidity"
              :label="txt.startValidity"
              :disabled="isFiltersDisabled"
            />
            <AGDatePicker
              id="datepickerEndValidity"
              v-model="filters.endValidity"
              :label="txt.endValidity"
              :disabled="isFiltersDisabled"
            />
            <AGDatePickerRange
              id="datepickerUpdated"
              v-model="filters.updated"
              :label="txt.updated"
              :disabled="isFiltersDisabled"
            />
            <SelectMultiple
              v-if="filterSelects.groupAmendedBy"
              id="selectAmendedBy"
              v-model="filters.amendedBy"
              :has-select-all="true"
              :label="txt.amendedBy"
              :items="filterSelects.groupAmendedBy"
              :disabled="isFiltersDisabled"
            />
          </template>
        </FilterEncapsulated>

        <ExportButton class="ml-4" :disabled="loadingTable" @click="onExport"></ExportButton>
      </v-card-title>
      <v-card-text class="px-0 pb-0">
        <TableCboClassification
          :table-title="txt.tableTitle"
          :loading-table="loadingTable"
          :headers="headers"
          :items="cboClassificationList"
          :pagination-filter="cboClassificationPagination"
          :total-items="totalCboClassification"
          @setPagination="setPagination"
        >
        </TableCboClassification>
      </v-card-text>
    </v-card>
  </v-main>
</template>

<script>
import { eventBus } from '@/main.js';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'Horarios-Jornadas',
  components: {
    Header: () => import('../../commons/Header.vue'),
    AGSwitch: () => import('../../commons/AGSwitch.vue'),
    TableSearch: () => import('../../commons/TableSearch.vue'),
    FilterEncapsulated: () => import('../../commons/FilterEncapsulated.vue'),
    SelectMultiple: () => import('../../commons/SelectMultiple.vue'),
    AGDatePicker: () => import('../../commons/AGDatePicker.vue'),
    AGDatePickerRange: () => import('../../commons/AGDatePickerRange.vue'),
    ExportButton: () => import('../../commons/ExportButton.vue'),
    TableCboClassification: () => import('./components/TableHorariosJornadas.vue'),
  },

  data() {
    return {
      disableApplyFilterButton: true,
      isApprenticeshipQuotaCount: 50,
      heightForDialog: '1300px',
      isNotApprenticeshipQuotaCount: 50,
      filters: {
        family: [],
        occupation: [],
        education: [],
        isApprenticeshipQuota: false,
        isNotApprenticeshipQuota: false,
        professionalRegulatoryBody: [],
        startValidity: '',
        endValidity: '',
        updated: [],
        amendedBy: [],
      },

      itemsBreadCrumb: [
        {
          title: 'Home',
          disabled: false,
          href: 'home',
        },
        {
          title: 'Configurações',
          disabled: true,
        },
        {
          title: 'e-Social',
          disabled: true,
        },
        {
          title: 'Parâmetros de análise ',
          disabled: false,
          href: '/cadastros-gerenciais/e-social/analysis-parameters',
        },
        {
          title: 'Classificação Brasileira de Ocupações - CBO',
          disabled: true,
          active: true,
        },
      ],

      styleForSearch: 'max-width:638px !important; min-width:638px !important;',
      txt: {
        family: 'Família',
        occupation: 'Ocupação',
        education: 'Escolaridade mínima',
        professionalRegulatoryBody: 'Órgão de classe',
        updated: 'Atualização',
        startValidity: 'Início de vigência',
        endValidity: 'Fim de vigência',
        amendedBy: 'Atualizado por',
        cota: 'Cota de aprendizagem',
        cnpj: 'Filtrar por CNPJ',
        tableTitle: 'Classificação Brasileira de Ocupações',
        pageTitle: 'Classificação Brasileira de Ocupações (CBO)',
        pageSubtitle: 'Gerencie a relação de ocupações conforme a Classificação Nacional de Atividades Econômicas',
        tableSearchLabel: 'Pesquisar por Título/Sinônimo, ocupação ou família',
        msgToast:
          "<div class='row'><div class='col-8 ml-0 mr-0 pl-0 pr-0 ' style='text-align: left;'>Sua solicitação de exportação foi recebida com sucesso! </br> Faça o download assim que o arquivo estiver disponível no menu 'Analytics' na opção 'Downloads' </div> <div class='col-4 ml-0 mr-0 pt-5 mt-4 pl-0 pr-0'><a href='/documents/download' style='text-decoration:none;'>Clique aqui para acessar</a></div></div>",
      },

      search: '',

      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },

      switches: [
        {
          id: 'switch_isApprenticeshipQuota',
          label: 'Sim',
          value: true,
          disabled: false,
        },
        {
          id: 'switch_isNotApprenticeshipQuota',
          label: 'Não',
          value: false,
          disabled: false,
        },
      ],

      headers: [
        { text: '', value: 'id_column', width: '41px', sortable: false },
        { text: 'Ocupação', align: 'start', value: 'occupation', width: '64px', sortable: false },
        { text: 'Título/Sinônimo', align: 'start', value: 'synonyms', width: '104px', sortable: false },
        { text: 'Família', align: 'start', value: 'family', width: '47px', sortable: true },
        { text: 'Descrição Sumária', align: 'start', value: 'summaryDescription', width: '120px', sortable: false },
        {
          text: 'Condições Gerais de Exercício',
          align: 'start',
          value: 'workingConditions',
          width: '193px',
          sortable: false,
        },
        {
          text: 'Formação e Experiência',
          align: 'start',
          value: 'formationExperience',
          width: '154px',
          sortable: false,
        },
        { text: 'Escolaridade Mínima', align: 'start', value: 'education', width: '135px', sortable: false },
        {
          text: 'Cota de Aprendizagem',
          align: 'start',
          value: 'apprenticeshipQuota',
          width: '145px',
          sortable: false,
        },
        {
          text: 'Órgão de classe',
          align: 'start',
          value: 'professionalRegulatoryBody',
          width: '103px',
          sortable: false,
        },
        { text: 'Regulamentação', align: 'start', value: 'regulation', width: '108px', sortable: false },
        { text: 'Notas', align: 'start', value: 'note', width: '38px', sortable: false },
        { text: 'Ínicio de Vigência', align: 'start', value: 'startValidity', width: '113px', sortable: false },
        { text: 'Fim de Vigência', value: 'endValidity', width: '101px', sortable: false },
        { text: 'Atualização', value: 'updated', width: '76px', sortable: false },
        { text: 'Atualizado por', value: 'amendedBy', width: '94px', sortable: false },
        { text: 'Ações', align: 'start', value: 'actions', width: '40px', sortable: false },
      ],
    };
  },

  computed: {
    ...mapState('usuarios', { users: (state) => state.users }),
    ...mapGetters({
      loadingCboList: 'cadastrosGerenciais/cboModule/getLoadingCboClassificationList',
      filtersSaved: 'cadastrosGerenciais/cboModule/getFiltersSaved',
      cboClassificationList: 'cadastrosGerenciais/cboModule/getCboClassificationList',
      cboClassificationPagination: 'cadastrosGerenciais/cboModule/getCboClassificationPagination',
      totalCboClassification: 'cadastrosGerenciais/cboModule/getCboClassificationTotal',
      scheduleReport: 'cadastrosGerenciais/cboModule/getScheduleReport',
      defaultFilterState: 'cadastrosGerenciais/cboModule/getDefaultFilterState',
      filterSelects: 'cadastrosGerenciais/cboModule/getFiltersSelect',
    }),

    loadingTable() {
      return this.loadingCboList;
    },

    loadingAllFilters() {
      return this.loadingFilters;
    },

    isFiltersDisabled() {
      return this.loadingAllFilters;
    },
  },

  watch: {
    filters: {
      handler(newFilterValue) {
        let defaultFilters = cloneDeep(this.defaultFilterState);
        delete defaultFilters.filterTerm;
        if (JSON.stringify(newFilterValue) === JSON.stringify(defaultFilters)) {
          this.disableApplyFilterButton = true;
        } else {
          this.disableApplyFilterButton = false;
        }
      },

      deep: true,
    },
  },

  created() {
    this.fetchSelectFilters();
  },

  methods: {
    ...mapActions('usuarios', ['getUsers']),
    ...mapActions('cadastrosGerenciais/cboModule', [
      'fetchSelectFilters',
      'setCboClassificationPagination',
      'searchByCBO',
      'applyFilters',
      'clearFilters',
      'fetchTableByTerm',
      'exportCboClassifications',
    ]),

    onClickAdd() {
      this.$router.push('/cadastros-gerenciais/e-social/analysis-parameters/cbo/cbo-form');
    },

    toggleSwitch(item) {
      if (item.id === 'switch_isNotApprenticeshipQuota') {
        if (this.filters.isNotApprenticeshipQuota === true) {
          this.filters.isNotApprenticeshipQuota = true;
          this.switches[1].value = false;
          this.filters.isApprenticeshipQuota = false;
        } else {
          this.filters.isNotApprenticeshipQuota = false;
          this.switches[1].value = false;
          this.filters.isApprenticeshipQuota = false;
        }
      } else if (item.id === 'switch_isApprenticeshipQuota') {
        if (this.filters.switch_isApprenticeshipQuota === true) {
          this.filters.isNotApprenticeshipQuota = false;
          this.switches[0].value = false;
          this.filters.switch_isApprenticeshipQuota = true;
        } else {
          this.filters.isNotApprenticeshipQuota = false;
          this.switches[0].value = false;
          this.filters.switch_isApprenticeshipQuota = false;
        }
      }
    },

    setPagination(pagination) {
      this.setCboClassificationPagination(pagination);
    },

    onInputSearch(input) {
      this.fetchTableByTerm(input);
    },

    applyFilter() {
      this.applyFilters(this.filters);
    },

    clearFiltersLocal() {
      this.clearFilters();
    },

    closeFilterDialog() {
      this.filters = cloneDeep(this.filtersSaved);
    },

    async onExport() {
      await this.exportCboClassifications();

      if (this.scheduleReport) {
        this.showToast(this.txt.msgToast, 'success', 78);
      } else {
        this.showToast('Erro ao exportar relatório.', 'error', 78);
      }
    },

    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/design/variables.scss';

.divSearch {
  min-width: 638px !important;
}
.titleForFilterSwitch {
  padding-top: $spacing-05;
  font-family: $font-family-base;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  margin-bottom: 0;
  color: $neutral-color-high-light;
}
.addPaddingToSwitch {
  padding-top: 2px !important;
  padding-left: 8px;
  padding-right: 8px;
}
.filters-dropdown-content {
  div {
    padding-top: $spacing-05;
  }
}
::v-deep .filters-dropdown {
  max-height: 1000px;
  height: 1000px;
}
</style>
